import cn from 'classnames';

import { generateCategoryPageUrl } from '@/components/category/helpers';
import { BreadcrumbProps } from './types';
import RenderedLink from './RenderedLink';

export default function Breadcrumb({
  breadcrumb,
  productTitle,
  isMobile,
  className,
}: BreadcrumbProps) {
  if (breadcrumb.length === 0) return null;

  if (isMobile) {
    const lastItem = breadcrumb[breadcrumb.length - 1];
    const link = generateCategoryPageUrl(lastItem.name, lastItem.pk);

    return (
      <div className={cn('flex items-center text-xs', className)}>
        <RenderedLink link={link} title={lastItem.name} isMobile={isMobile} />
      </div>
    );
  }

  return (
    <div className={cn('flex items-center gap-1 text-xs', className)}>
      <div className="flex items-center gap-1">
        <RenderedLink link="/" title="Home" />
        <span className="block text-gray-700/50">/</span>
      </div>
      {breadcrumb.map((c) => {
        const link = generateCategoryPageUrl(c.name, c.pk);

        return (
          <div key={c.name} className="flex items-center gap-1">
            <RenderedLink link={link} title={c.name} />
            <span className="block text-gray-700/50">/</span>
          </div>
        );
      })}
      <span className="max-w-[300px] truncate text-gray-700/100">
        {productTitle}
      </span>
    </div>
  );
}
