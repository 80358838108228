import { useMemo, useState } from 'react';
import Card from './Card';
import { PopularCategory } from '@/settings/popular-categories/types';

const MobileView = ({ categories }: { categories: PopularCategory[] }) => {
  const [seeMore, setSeeMore] = useState(false);

  const slicedCategories = useMemo(
    () => (seeMore ? categories : categories.slice(0, 6)),
    [seeMore],
  );

  const shouldShowSeeMore = categories.length > 6;

  return (
    <div className="md:hidden">
      <div className="mt-6 grid grid-cols-3 gap-x-5 gap-y-8">
        {slicedCategories.map((category) => (
          <Card key={category.handle ?? category.pk} category={category} />
        ))}
      </div>
      {shouldShowSeeMore && (
        <button
          onClick={() => setSeeMore((prevState) => !prevState)}
          type="button"
          className="mx-auto mt-8 flex w-full items-center justify-center rounded-md border border-gray-700 py-2 text-sm xs:w-[250px]"
        >
          See {seeMore ? 'less' : 'more'}
        </button>
      )}
    </div>
  );
};

export default MobileView;
