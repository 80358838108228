import { CategoryTree } from '@lambda/apis/src/category/types';
import { OfferTree, SelectionT } from '@/lib/collections/catalogue/helpers';
import { Query } from './types';

export const getVariantPrice = (
  condition: string,
  selection: SelectionT,
  offerTree: OfferTree,
) => {
  const variantOffer = offerTree.get({
    ...selection,
    condition,
  });

  if (variantOffer == null) return null;

  return variantOffer.price;
};

export const getSuggestedPrice = (
  query: Query | null,
  offerTree: OfferTree,
) => {
  if (query?.suggested) {
    const variantOffer = offerTree.get({ ...query });

    if (variantOffer == null) return null;

    return variantOffer.price;
  }

  return null;
};

export const generateOldProductSpecifications = (specifications: string) => {
  const listElements = specifications.match(/<li>(.*?)<\/li>/g);
  const listArray = listElements
    ? listElements.map((item: string) => {
        const parts = item.replace(/<\/?li>/g, '').split(': ');

        return { key: parts[0], value: parts[1] };
      })
    : [];

  return listArray;
};

export function streamlineBreadcrumb(
  categoryTree: CategoryTree | null,
  currentCategoryPk: string,
): { name: string; pk: string }[] {
  if (!categoryTree) return [];

  const breadcrumb: { name: string; pk: string }[] = [];
  let isActive = false;

  function traverse(node: CategoryTree) {
    breadcrumb.push({ name: node.name, pk: node.pk });

    if (node.pk === currentCategoryPk && node.isActive === 1) isActive = true;

    if (node.children?.length) traverse(node.children[0]);
  }

  traverse(categoryTree);

  return isActive ? breadcrumb : [];
}
