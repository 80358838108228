import Link from 'next/link';
import Image from 'next/future/image';
import { categoryNameToHandle } from '@lambda/reebelo/src/commonHelpers';
import cn from 'classnames';
import { generateCategoryPageUrl } from '@/components/category/helpers';
import { getEdgeUrl } from '@/lib/getEdgeUrl';
import { PopularCategory } from '@/settings/popular-categories/types';

const Content = ({
  isCartPage,
  badge,
  categoryImage,
  title,
}: {
  isCartPage: boolean;
  badge?: string;
  categoryImage: string;
  title: string;
}) => (
  <div className="flex flex-col items-center">
    <div
      className={cn(
        'relative flex h-20 w-20 items-center justify-center rounded-full bg-gray-200 xxs:h-24 xxs:w-24',
        {
          'lg:h-28 lg:w-28 xl:h-32 xl:w-32': !isCartPage,
        },
      )}
    >
      {badge && (
        <div className="absolute left-0 top-0 rounded-full bg-gray-700 px-2 py-1 text-[10px] text-white">
          {badge}
        </div>
      )}
      <div
        className={cn('h-14 w-14 xxs:h-16 xxs:w-16', {
          'lg:h-20 lg:w-20 xl:h-24 xl:w-24': !isCartPage,
        })}
      >
        <Image
          width={100}
          height={100}
          className="h-full w-full object-contain"
          src={categoryImage}
          alt={title}
          quality={100}
        />
      </div>
    </div>
    <h3 className="mt-3 w-full truncate text-center text-sm">{title}</h3>
  </div>
);

const Card = ({
  category,
  isCartPage = false,
}: {
  category: PopularCategory;
  isCartPage?: boolean;
}) => {
  const { badge, handle, pk, title, imageUrl } = category;

  const link = handle
    ? `/collections/${handle}`
    : generateCategoryPageUrl(title, pk as string);

  const categoryImage =
    imageUrl ??
    getEdgeUrl(
      `images/homepage/popular-categories/${categoryNameToHandle(title)}.png`,
    );

  if (badge && badge === 'Soon') {
    return (
      <Content
        isCartPage={isCartPage}
        badge={badge}
        categoryImage={categoryImage}
        title={title}
      />
    );
  }

  return (
    <Link href={link} key={handle ?? pk}>
      <a>
        <Content
          isCartPage={isCartPage}
          badge={badge}
          categoryImage={categoryImage}
          title={title}
        />
      </a>
    </Link>
  );
};

export default Card;
