import cn from 'classnames';
import Section from '@/components/commons/Section';
import popularCategories from '@/settings/popular-categories';
import DesktopView from '@/components/commons/CategoriesLayout/DesktopView';
import MobileView from '@/components/commons/CategoriesLayout/MobileView';
import Carousel from '@/components/commons/CategoriesLayout/Carousel';

const PopularCategories = ({
  isHomePage,
  isCartPage,
  className,
}: {
  isHomePage?: boolean;
  isCartPage?: boolean;
  className?: string;
}) => (
  <Section className={className}>
    <div className={cn({ 'reebelo-container': isHomePage })}>
      <h2
        className={cn('flex items-center', {
          'text-lg font-semibold md:text-xl': !isCartPage,
          'text-base font-bold': isCartPage,
        })}
      >
        Popular Categories
      </h2>
      {isHomePage ? (
        <>
          <DesktopView categories={popularCategories} />
          <MobileView categories={popularCategories} />
        </>
      ) : (
        <Carousel
          popularCategories={popularCategories}
          isCartPage={isCartPage}
        />
      )}
    </div>
  </Section>
);

export default PopularCategories;
