import { PopularCategory } from '@/settings/popular-categories/types';
import Card from './Card';

const DesktopView = ({ categories }: { categories: PopularCategory[] }) => (
  <div className="mt-6 hidden gap-x-5 gap-y-8 md:grid md:grid-cols-4 lg:grid-cols-6">
    {categories.map((category) => (
      <Card key={category.handle ?? category.pk} category={category} />
    ))}
  </div>
);

export default DesktopView;
