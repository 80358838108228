import { replacePlusSymbol } from 'pages/products/[handle]';
import { categoryNameToHandle } from '@lambda/reebelo/src/commonHelpers';
import { handleFreeReturn } from '@lambda/commons/helpers';
import { CategoryTree } from '@lambda/apis/src/category/types';
import settings from '@/settings';
import categories from '@/settings/categories';

const freeReturnDays = handleFreeReturn(
  settings.store,
  settings.free_return_days,
);

// Handle Meta Tags
export const getCollectionMetaContent = (categoryName: string) => {
  const isUSStore = settings.store === 'reebelo-us';

  if (isUSStore) {
    return `Save big on Reebelo's widest collections of refurbished ${replacePlusSymbol(
      categoryName || 'products',
    )}. Free delivery. ${freeReturnDays} days free returns. Up to ${
      settings.warranty_month
    } months warranty. ${
      settings.country
    }'s leading marketplace for sustainable tech.`;
  }

  return `Save big on Reebelo's widest collections of new and refurbished ${replacePlusSymbol(
    categoryName || 'products',
  )}. Free delivery. ${freeReturnDays} days free returns. Up to ${
    settings.warranty_month
  } months warranty. ${
    settings.country
  }'s leading marketplace for sustainable tech.`;
};

// Handle Page Title
export const getPreTitle = () => {
  const isUSStore = settings.store === 'reebelo-us';

  if (isUSStore) return `Refurbished`;

  return `Refurbished`;
};

export const generateCategoryPageUrl = (
  categoryName: string,
  categoryPk: string,
) => {
  const categoryHandle = categoryNameToHandle(categoryName);

  if (categoryHandle in categories) return `/category/${categoryHandle}`;

  switch (categoryHandle) {
    case 'smartphones':
      return '/collections/smartphones';
    case 'smartwatches':
      return '/collections/smartwatches';
    case 'tablets':
      return '/collections/tablets';
    case 'laptops':
      return '/collections/laptops';
    case 'baby-products-toys':
      return `/category/baby`;
    default:
      return `/collections/${categoryHandle}/${categoryPk}`;
  }
};

export function notNull<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

/**
 * Recursively searches for a category in the category tree by its primary key (pk)
 * @param categoryTree - The root category tree
 * @param pk - The pk of the category to search for
 * @returns The category tree node or null if not found
 */
export function findCategoryByPk(
  categoryTree: CategoryTree,
  pk: string,
): CategoryTree | null {
  if (categoryTree.pk === pk) return categoryTree;

  // Recurse through children if necessary
  return (
    categoryTree.children
      ?.map((child) => findCategoryByPk(child, pk))
      .find((result) => result !== null) || null
  );
}

/**
 * Helper function to recursively gather all child category names
 * @param category - The category node to process
 * @returns A list of category names including all nested subcategories
 */

export function getChildCategoryNames(category: CategoryTree): string[] {
  // Start with the current category's name
  const names = [category.name];

  // Recursively add names of all children
  category.children.forEach((child) => {
    if (child.isActive === 1) names.push(...getChildCategoryNames(child));
  });

  return names;
}
